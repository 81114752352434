/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
//import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';


/**
 * import modules
 */
import Slider from './modules/slider';
import Parts from './modules/parts';
import CookiePolicy from './modules/cookiePolicy';
import Smoothscroll from './modules/smoothscroll';
import SideNavi from './modules/sideNavi';


const target = $('main').attr('id');
switch (target) {
  case 'productsTop':
  case 'top':
    console.log(target)
    const sideNavi = new SideNavi();
    sideNavi.init();
    break;
  case 'productsUnder':
    console.log(target)
    const slider = new Slider();
    slider.init();
    break;
}

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    /* pagetop */
    const smoothscroll = new Smoothscroll();
    smoothscroll.init();

    /* 流用 */
    const parts = new Parts();
    parts.init();

    /* 流用 */
    const cookiePolicy = new CookiePolicy();
    cookiePolicy.init();

  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {
    this.init_deSVG('.js-desvg');
    this.init_objectFitImages('.object-fit');
    this.init_smoothScroll();
  }

  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
