import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

/**
 * SideNavi
 * @constructor
 */
class SideNavi {
  constructor() {}
  /**
   * SideNavi
   */
  init() {

    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;

//    const sideNavi = new TimelineMax();
//    new ScrollMagic.Scene({
//      triggerElement: '.l-footer',
//      triggerHook: 'onEenter',
//      offset: '0',
//      duration: 0,
//      reverse: true,
//    })
//    .setTween(sideNavi)
//    .addIndicators({name: "sideNavi"})
//    .setPin('.m-sideNavi')
//    .setClassToggle('.m-sideNavi', 'abso')
//    .addTo(controller);
    
    
    const sideNavi = new TimelineMax();
    new ScrollMagic.Scene({
      triggerElement: '.l-footer',
      triggerHook: 'onCenter',
      offset: '-417',
      duration: 0,
      reverse: true,
    })
    .setTween(sideNavi)
//    .addIndicators({name: "sideNavi"})
//    .setPin('.m-sideNavi')
    .setClassToggle('.m-sideNavi', 'abso')
    .addTo(controller);

  }
};
export default SideNavi;
