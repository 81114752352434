/**
 * CookiePolicy
 * @constructor
 */
class CookiePolicy {
  constructor() {}
  /**
   * CookiePolicy
   */
  init() {
    
    $(function() {
      var next_year = new Date();
      next_year.setFullYear(next_year.getFullYear()+1);
      var exists_cookie = function(key, value) {
        var array = document.cookie.split(";");
        var flag = true;
        $.each(array, function(index, item) {
          var key_cookie = item.split("=");
          if (key_cookie[0] === key && key_cookie[1] === value) {
            flag = false;
          }
        });
        return flag;
      };
      var add_cookie = function(key) {
        document.cookie = key;
      };
      var remove_cookie = function(key) {
        document.cookie = key + "=; max-age=0;";
      };
      var add_modal_html = function(value) {
        var html = value;
        $("header").after(html);
      };
      var add_modal_styles = function(value) {
        var Style = document.createElement("style");
        Style.type = "text/css";
        Style.innerText = value;
        document
          .getElementsByTagName("HEAD")
          .item(0)
          .appendChild(Style);
      };
      var remove_element = function(e) {
        $(e).remove();
      };
      var url = "https://www.stanley.co.jp/cookiepolicy.html";
      var modal_html =
        '<div id="cokkie-policy-modal"><p>当社のウェブサイトでは、利便性の維持及び向上等のためクッキーを使用しております。詳細は、<a target="_blank" href="' + url + '">クッキーポリシー</a>をご覧ください。クッキーポリシーに同意いただける場合は、同意ボタンを押してください。</p><span>同意する</span></div>';

      var modal_css =
        "#cokkie-policy-modal {width: 100vw;background-color: #eee;position: fixed;bottom: 0;left: 0;z-index: 9999;padding: 1.73vw 0 1.73vw 5.55vw;}#cokkie-policy-modal p {font-size: .9vw;letter-spacing: -1px;width: 80vw;}#cokkie-policy-modal a {text-decoration: underline;}#cokkie-policy-modal span {display: block;position: absolute;top: 50%;right: 5.38vw;transform: translateY(-50%);z-index: 99999;font-size: 1.04vw;padding: .79vw 2vw;border: 2px solid #222;}#cokkie-policy-modal span:hover {text-decoration: underline;cursor: pointer;}@media screen and (max-width: 768px) {#cokkie-policy-modal span {right: 1vw;font-size: 3vw;}#cokkie-policy-modal {padding:2vw 0 2vw 1vw;}#cokkie-policy-modal p {line-height: 1.5;font-size: 2.66vw;}}";

      if (exists_cookie(" accessFlg", "on")) {
        add_modal_html(modal_html);
        add_modal_styles(modal_css);
      }

      $("#cokkie-policy-modal span").on("click", function() {
        add_cookie("accessFlg=on; path=/ ;Expires="+next_year.toUTCString());
        remove_element("#cokkie-policy-modal");
      });

      // remove_cookie("accessFlg");
    });

  }
};

export default CookiePolicy;