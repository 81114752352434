/**
 * スムーススクロール
 */
class scroll {
  constructor() {}
  init() {
    var headerHeight = $("#header").height();
    var speed = 500;
    console.log($("#header").height())

    $('a[href^="#"]').click(function () {
      var href = $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - headerHeight;
      $("html, body").animate({
        scrollTop: position
      }, speed, "swing");
      return false;
    });

    setTimeout(function () {
      var url = $(location).attr('href');
      if (url.indexOf("?id=") == -1) {
        // ほかの処理
      } else {
        var url_sp = url.split("?id=");
        var hash = '#' + url_sp[url_sp.length - 1];
        var target = $(hash);
        var position = target.offset().top - headerHeight;
        $("html, body").animate({
          scrollTop: position
        }, speed, "swing");
      }
    }, 100);

  }
}

export default scroll;
