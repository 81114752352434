import Swiper from "swiper";
/**
 * Slider
 * @constructor
 */
class Slider {
  constructor() {}
  /**
   * Slider
   */
  init() {

    var pc = window.matchMedia("(min-width: 751px)").matches;
    var sp = window.matchMedia("(max-width: 750px)").matches;
    
    var sliderThumbnail = new Swiper('.swiper-container--thumbnail', {
      slidesPerView: 4,
      spaceBetween: 10,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    })
    var slider = new Swiper('.swiper-container--main', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: sliderThumbnail
      }
    })    
  }
};

export default Slider;