/**
 * Parts
 * @constructor
 */
class Parts {
  constructor() {}
  /**
   * Parts
   */
  init() {
    
    var onClickOptionFlg = false;
    $(function() {
      if ($("svg").length > 0) {
        function svgSizeCheck() {
          $("svg").each(function() {
            var _this = $(this);
            if (_this.css("display") == "none") return true;
            var _width = _this.width();
            var _viewBox = _this["context"].viewBox["baseVal"];
            var _viewWidth = _viewBox["width"];
            var _viewHeight = _viewBox["height"];
            var _setHeight = (_viewHeight * _width) / _viewWidth;
            _this.height(_setHeight);
          });
        }
        var timer = false;
        $(window).resize(function() {
          if (timer !== false) clearTimeout(timer);
          timer = setTimeout(function() {
            svgSizeCheck();
          }, 200);
        });
      }

      $('a[href^="#"]')
        .not("[href^='#tab']")
        .on("click", function() {
          var header_height = $(".header").height();
          var speed = 500;
          var href = $(this).attr("href");
          var target = $(href == "#" || href == "" ? "html" : href);
          var position = target.offset().top - header_height;

          $("html, body").animate({ scrollTop: position }, speed, "swing");
          return false;
        });

      // topページtopicスライダーのソート
      if ($(".news-swiper-slide").length) {
        var newsSlide = $(".news-swiper-slide");
        var swiperNews = document.querySelector(".news-swiper").swiper;
        var sortSlide = function($value) {
          swiperNews.removeAllSlides();
          elements = newsSlide.filter("[data-sort=" + $value + "]");
          if (elements.length <= 2) {
            if ($(window).width() > 768) {
              $(".news-swiper")
                .children($(".swiper-wrapper"))
                .css("justify-content", "center");
            }
          }
          elements.each(function(index, el) {
            swiperNews.addSlide(index, el);
          });
        };
        sortSlide("すべて");
        $(".js-sort-btn").on("click", function() {
          var sort_category = $(this).data("category");
          sortSlide(sort_category);
          $(".news-swiper-slide").css("animation", "sort_slide .5s");
        });
      }

      // アコーディオン
      $(".js-toggle-trigger").on("click", function(e) {
        e.stopPropagation();
        $(this)
          .children(".js-toggle-item")
          .slideToggle();
      });
      $(".js-toggle-trigger").on("click", "a", function(e) {
        e.stopPropagation();
      });

      // アクティブ付与
      $(".js-active").on("click", function(e) {
        e.stopPropagation();
        $(".js-active").removeClass("is-active");
        $(this).toggleClass("is-active");
      });

      // ハンバーガーメニュー
      $(".js-menu-trigger").on("click", function(e) {
        e.stopPropagation();
        $(this).toggleClass("is-active");
        $(".header").toggleClass("is-open");
        $(".js-active-menu-item").toggleClass("is-active");
      });

      // ヘッダーのツールチップ
      var timerId;
      var $triggerTooltip = $(".js-trigger-tooltip-toggle");

      $triggerTooltip.on("mouseenter", function() {
        clearTimeout(timerId);
        height_header = $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .height();
        var width_target = $(this).offset().left;
        var $self = $(this);
        $triggerTooltip.removeClass("is-open");
        $self.addClass("is-open");
        var tooltipId = $(this).data("trigger-tooltip");
        var tooltipEl = $("[data-tooltip=" + tooltipId + "]");
        $(".header_tooltip").removeClass("is-tooltip-open");
        tooltipEl.addClass("is-tooltip-open");
        if ($(".js-localnav").length) {
          if ($("header").hasClass("is-minify")) {
            height_localNav = 0;
            $(".js-localnav").each(function() {
              height_localNav += $(".js-localnav").height();
            });
          }
        }
      });
      $(".header_navber_link")
        .on("mouseleave", function() {
          timerId = setTimeout(function() {
            $triggerTooltip.removeClass("is-open");
            $(".header_tooltip").removeClass("is-tooltip-open");
            $(".header_tooltip").css("top", "");
          }, 600);
        })
        .on("mouseover", function() {
          clearTimeout(timerId);
        });
      $("body").on("click", function() {
        if (!$triggerTooltip.hasClass("is-open")) return;
        $triggerTooltip.removeClass("is-open");
        $(".header_tooltip").removeClass("is-tooltip-open");
        $(".header_tooltip").css("top", "");
      });

      // ヘッダーアイコンボタン
      var $optionBtn = $(".js-options-toggle");
      var $hideEl = $(".js-options");
      var $children = $(".header_options").children();
      var $otherEl = $(".is-other").children();
      var $showchildren = $(".is-show").find($children);
      $optionBtn.on("click", function() {
        var $self = $(this);
        var attr = $self.attr("data-option");
        var $targetEl = $("[data-option-target=" + attr + "]");

        if ($self.hasClass("is-active")) return;

        if ($self.hasClass("is-open")) {
          $otherEl.removeClass("is-active");
          $self.removeClass("is-open");
          // $children.show();
          $hideEl.show();
          $children.hide();
        } else {
          $otherEl.addClass("is-active");
          $self.removeClass("is-active").addClass("is-open");
          $children.hide();
          $hideEl.hide();
          $targetEl.show();
        }

        if ($(window).width() > 768) {
          if ($children.parents("is-show")) {
            $showchildren.css("display", "none");
          }
        }
      });

      $children.on("click", function() {
        // $children.hide();
        // $otherEl.removeClass("is-active");
        // $optionBtn.removeClass("is-open");
      });

      $otherEl.find("a").on("click", function(e) {
        if ($optionBtn.hasClass("is-open")) e.preventDefault();
      });

      //文字サイズ切り替え
      var userAgent = window.navigator.userAgent.toLowerCase();
      var $txtSizeBtn = $(".txtSize_item");
      $txtSizeBtn.on("click", function() {
        var $self = $(this);
        $txtSizeBtn.removeClass("is-active");
        $self.addClass("is-active");
      });

      $(function() {
        // 中ボタンクリック
        $(".btn-m").click(function() {
          $("html.large").removeClass();
          $("html").addClass("medium");
          $.cookie("fontsize", "medium", { expires: 30, path: "/" });
          $children.hide();
          $otherEl.removeClass("is-active");
          $optionBtn.removeClass("is-open");
          $(".header_options_txtSize").css("display", "none");
          if (window.matchMedia("(min-width: 769px)").matches) {
            // PC表示の時の処理
            // var header_height = $("html.large header").outerHeight();
            // var header_height_l = $("html.large header").outerHeight();
            // $("main").css("margin-top", header_height - 0.5);
            if (userAgent.indexOf("trident") != -1) {
              // var header_height_l = $("html.large header").outerHeight();
              // $("main").css("margin-top", header_height_l - 26);
              // $("main").css("margin-top", header_height - 0.5);
              $("html").css("font-size", "0.07vw");
            } else if (userAgent.indexOf("msie") != -1) {
              // $("html").css("font-size", "0.07vw");
            }
          } else {
            // スマホ表示の時の処理
            if (userAgent.indexOf("trident") != -1) {
              // var header_height_l = $("html.large header").outerHeight();
              $("html").css("font-size", "0.125vw");
            } else if (userAgent.indexOf("msie") != -1) {
              // $("html").css("font-size", "0.125vw");
            }
          }
          // document.cookie = "fontsize=medium; path=/ expires=30";
        });

        // 大ボタンクリック
        $(".btn-l").click(function() {
          $("html:not(.large)").removeClass();
          $("html").addClass("large");
          $.cookie("fontsize", "large", { expires: 30, path: "/" });
          $children.hide();
          $otherEl.removeClass("is-active");
          $optionBtn.removeClass("is-open");
          $(".header_options_txtSize").css("display", "none");
          // document.cookie = "fontsize=large; path=/ expires=30";
          if (window.matchMedia("(min-width: 769px)").matches) {
            // PC表示の時の処理
            // var header_height = $("header").outerHeight();
            // var header_height_l = $("html.large header").outerHeight();
            // $("main").css("margin-top", header_height - 0.5);
            if (userAgent.indexOf("trident") != -1) {
              // var header_height_l = $("html.large header").outerHeight();
              $("html").css("font-size", "0.08vw");
            } else if (userAgent.indexOf("msie") != -1) {
              // $("html").css("font-size", "0.08vw");
            }
          } else {
            // スマホ表示の時の処理
            if (userAgent.indexOf("trident") != -1) {
              // var header_height_l = $("html.large header").outerHeight();
              $("html").css("font-size", "0.15vw");
            } else if (userAgent.indexOf("msie") != -1) {
              // $("html").css("font-size", "0.15vw");
            }
          }
        });
      });

      /*======================================================
        ページ読み込み時
      ======================================================*/

//      var cookieLoad = function() {
//        var cookie = $.cookie("fontsize");
//        var userAgent = window.navigator.userAgent.toLowerCase();
//        //cookie「large」がある場合
//        if (cookie == "large") {
//          $("html:not(.large)").removeClass();
//          $("html").addClass("large");
//          $(".btn-l")
//            .parent()
//            .addClass("is-active");
//          if (userAgent.indexOf("trident") != -1) {
//            // $("html").css("font-size", "0.08vw");
//            var w = $(window).width();
//            var x = 768;
//            if (w <= x) {
//              setTimeout(function() {
//                $(".large").css("font-size", "0.15vw");
//              }, 1000);
//            } else {
//              setTimeout(function() {
//                $(".large").css("font-size", "0.08vw");
//              }, 800);
//            }
//          } else if (userAgent.indexOf("msie") != -1) {
//            // $("html").css("font-size", "0.08vw");
//            var w = $(window).width();
//            var x = 768;
//            if (w <= x) {
//              setTimeout(function() {
//                $(".large").css("font-size", "0.15vw");
//              }, 1000);
//            } else {
//              setTimeout(function() {
//                $(".large").css("font-size", "0.08vw");
//              }, 800);
//            }
//          }
//        } else {
//          $("html.large").removeClass();
//          $("html").addClass("medium");
//          $(".btn-m")
//            .parent()
//            .addClass("is-active");
//          if (userAgent.indexOf("trident") != -1) {
//            // $("html").css("font-size", "0.07vw");
//            var w = $(window).width();
//            var x = 768;
//            if (w <= x) {
//              setTimeout(function() {
//                $(".medium").css("font-size", "0.125vw");
//              }, 1000);
//            } else {
//              setTimeout(function() {
//                $(".medium").css("font-size", "0.07vw");
//              }, 800);
//            }
//          } else if (userAgent.indexOf("msie") != -1) {
//            // $("html").css("font-size", "0.07vw");
//            var w = $(window).width();
//            var x = 768;
//            if (w <= x) {
//              setTimeout(function() {
//                $(".medium").css("font-size", "0.125vw");
//              }, 1000);
//            } else {
//              setTimeout(function() {
//                $(".medium").css("font-size", "0.07vw");
//              }, 800);
//            }
//          }
//        }
//      };
//      cookieLoad();

      $(window).resize(function() {
        //IE
        function userAgentIE() {
          return userAgent.indexOf("trident") != -1;
        }
        function textsize(fontsize) {
          $("html").css("font-size", fontsize);
        }

        var mediaQueryPc = window.matchMedia("(min-width: 769px)").matches;
        var mediaQuerySp = window.matchMedia("(max-width: 768px)").matches;

        var sizeMedium = $("html").hasClass("medium");
        var sizeLarge = $("html").hasClass("large");

        if (!userAgentIE()) return;
        if (!sizeMedium && !sizeLarge) return;

        if ($("html").hasClass("medium") && mediaQueryPc) return textsize("0.07vw");
        if ($("html").hasClass("medium") && mediaQuerySp)
          return textsize("0.125vw");
        if ($("html").hasClass("large") && mediaQueryPc) return textsize("0.08vw");
        if ($("html").hasClass("large") && mediaQuerySp) return textsize("0.15vw");
      });

      // history.replaceState(
      //   null,
      //   document.getElementsByTagName("title")[0].innerHTML,
      //   nullz
      // );
      // window.addEventListener("popstate", function(e) {
      //   window.location.reload();
      // });

      // window.onbeforeunload = function() {
      //   // IE用。ここは空でOKです
      // };
      // window.onunload = function() {
      //   // IE以外用。ここは空でOKです
      // };

      var $langBtn = $(".lang_item");
      $langBtn.on("click", function() {
        var $self = $(this);
        $langBtn.removeClass("is-active");
        $self.addClass("is-active");
      });

      $(".tab-common--ir").each(function() {
        $(this)
          .children(".tab-common_panel")
          .eq(0)
          .css("display", "block");
      });
      // タブ切り替え
      $(".tab-common_label").each(function() {
        if ($(this).hasClass("tab-common_label-en")) {
          $($(this).children()[0]).removeClass("tab-common_current");
          $($(this).children()[1]).addClass("tab-common_current");
          $($(this).nextAll()).css("display", "none");
          $(
            $(this)
              .next()
              .next()
          ).css("display", "block");
        } else {
          $($(this).children()[0]).addClass("tab-common_current");
        }
      });
      $(".tab-common--ir .tab-common_label_item").click(function() {
        // 〜〜コンテンツについての処理〜〜
        var index = $(this)
          .parent()
          .children()
          .index(this);
        // クリックされたタブと対象のコンテンツ
        var pair_content = $(this)
          .parent()
          .nextAll(".tab-common_panel");
        pair_content.css("display", "none");
        pair_content.eq(index).css("display", "block");
        // 〜〜タブについての処理〜〜
        // クリックされたタブの絞り込み
        var clicked_tab = $(this)
          .parent()
          .children(".tab-common_label_item");
        clicked_tab.removeClass("tab-common_current");
        $(this).addClass("tab-common_current");
      });

      // minify
      var header_minify = $("header").clone(true);
      $(header_minify).addClass("is-minify");
      var height_header = $("header").height();
      var height_localNav = 0;
      // ローカルナビの有無の判別
      if ($(".js-localnav").length) {
        var html_localNav = $(".js-localnav").clone(true);
      }

      $(window).scroll(function() {
        var scroll_top = $(window).scrollTop();

        if (scroll_top > height_header + height_localNav) {
          $("header").after(header_minify);
          $("header")
            .not(header_minify)
            .css("opacity", "0");
          $(header_minify).addClass("is-show");
          $(".js-noMargin-nav").css("margin-top", "0");
          if ($(".js-localnav").length) {
            //  pcのみ
            var w = $(window).width();
            var x = 768;
            if (w > x) {
              $(header_minify)
                .children(".header_inner")
                .after(html_localNav);
            }
          }
        } else {
          $("header").removeClass("is-show");
          $("header")
            .not(header_minify)
            .css("opacity", "1");
          $(".js-noMargin-nav").css("margin-top", "");
          if ($(".js-localnav").length) {
            $(html_localNav).remove();
          }
          $triggerTooltip.removeClass("is-open");
          $(".header_tooltip").removeClass("is-tooltip-open");
          $(".header_tooltip").css("top", "");
        }
      });
    });

  }
};

export default Parts;